










import { defineComponent } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import type { PropType } from '@nuxtjs/composition-api';
import { useNavigationState } from '~/composables';
import { LinkType } from '~/types/components/Link/LinkType';
import Link from '~/components/atoms/Link/Link.vue';

export default defineComponent({
  name: 'NavigationLink',
  components: {
    Link
  },
  props: {
    isPrimary: {
      type: Boolean,
      default: false
    },
    text: {
      type: String as PropType<VueI18n.TranslateResult>,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  setup () {
    const { toggleNavigation } = useNavigationState();

    return {
      toggleNavigation,
      LinkType
    };
  }
});

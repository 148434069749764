const changeBodyStyles = (positionValue: string) => {
  const bodyElement = document.querySelector('body');

  if (bodyElement) {
    bodyElement.style.position = ''; // position needs to be cleared for a correct clientHeight calculation below
    bodyElement.style.overflowY = bodyElement.scrollHeight > bodyElement.clientHeight ? 'scroll' : '';

    bodyElement.style.position = positionValue;
  }
};

export const disableHTMLScroll = () => {
  changeBodyStyles('fixed');
};
export const enableHTMLScroll = () => {
  changeBodyStyles('');
};

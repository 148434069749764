import type { CustomFieldsType } from '@vsf-enterprise/commercetools-types';

type CommerceToolsObject = {
    custom?: Maybe<CustomFieldsType>
}

export function extractCustomField(object: CommerceToolsObject, fieldName: string): string | undefined {
  return object.custom?.customFieldsRaw?.find(
    field => field.name === fieldName
  )?.value;
}


































import { defineComponent } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import NavCategoryList from '~/components/molecules/Navigation/NavCategoryList/NavCategoryList.vue';
import NavCategoryListItem from '~/components/molecules/Navigation/NavCategoryList/NavCategoryListItem.vue';
import { SCHEMA_ITEM_TYPE } from '~/constants/seo';
import { ROUTES_INFORMATION, ROUTES } from '~/constants/routes';
import NavigationLink from '~/components/molecules/Navigation/NavigationLink/NavigationLink.vue';

export default defineComponent({
  name: 'NavMainCategories',
  components: {
    NavCategoryListItem,
    NavCategoryList,
    NavigationLink
  },
  props: {
    firstLevelCategories: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      }
    },
    hasSchema: {
      type: Boolean,
      default: false
    }
  },
  setup(_props) {
    const { i18n } = useVSFContext();

    const shopAllButtonProps = {
      'is-primary': true,
      text: i18n.t('Shop All'),
      url: ROUTES_INFORMATION[ROUTES.SEARCH].SLUG,
      'data-testid': 'showAllProducts'
    };

    return {
      SCHEMA_ITEM_TYPE,
      shopAllButtonProps
    };
  }
});
































import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useNavigationState } from '~/composables';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import { SCHEMA_ITEM_PROP } from '~/constants/seo';
import { getCategoryImgUrl } from '~/helpers/category/customFields/index';
import SimpleLink from '~/components/atoms/SimpleLink/SimpleLink.vue';
import { buildCategoryPageUrl } from '~/helpers/category/buildCategoryPageUrl';

export default defineComponent({
  name: 'NavCategoryListItem',
  components: {
    ImgView,
    SimpleLink
  },
  props: {
    category: {
      type: Object as PropType<Category>,
      required: true
    },
    hasSchema: {
      type: Boolean,
      default: false
    },
    shouldSetFirstLevelCategory: {
      type: Boolean,
      default: false
    },
    dataTestid: {
      type: String,
      default: undefined
    }
  },
  setup(props: {category: Category, hasSchema: Boolean, shouldSetFirstLevelCategory: Boolean}) {
    const { toggleNavigation, setFirstLevelCategory } = useNavigationState();
    const targetCategoryUrl = computed(() => buildCategoryPageUrl(props.category.slug));
    const handleItemClick = () => {
      if (props.shouldSetFirstLevelCategory) {
        return setFirstLevelCategory(props.category.slug);
      }
      toggleNavigation();
    };
    const categoryImageUrl = computed(() => getCategoryImgUrl(props.category));
    const componentTag = computed(() => props.shouldSetFirstLevelCategory ? 'div' : 'SimpleLink');
    const simpleLinkProps = {
      to: targetCategoryUrl.value,
      itemprop: props.hasSchema ? SCHEMA_ITEM_PROP.URL : undefined
    };
    const componentProperties = computed(() => props.shouldSetFirstLevelCategory ? {} : simpleLinkProps);
    return {
      targetCategoryUrl,
      toggleNavigation,
      handleItemClick,
      SCHEMA_ITEM_PROP,
      categoryImageUrl,
      componentTag,
      componentProperties
    };
  }
});














import { defineComponent } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import NavFirstLevelCategory from '~/components/molecules/Navigation/NavFirstLevelCategory/NavFirstLevelCategory.vue';

export default defineComponent({
  name: 'NavBody',
  components: {
    NavFirstLevelCategory
  },
  props: {
    firstLevelCategories: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      }
    }
  }
});
